/* eslint-disable */

import axios from "axios";
import {
    setUserJWT,
    getUserJWT,
    setUser,
    setLocalStorageFromAuth,
    getApiURL,
} from "./HelperMethod";
import { useContext } from "react";
import { UserCompany } from "../store/StoreProvider";

const ApiService = () => {
    const [_, setCompanyName] = useContext(UserCompany);

    function authenticate(username, password, callBack) {
        return ax()
            .post("web/auth", { username, password })
            .then((data: any) => {
                data = data.data;
                if (data.token) {
                    setLocalStorageFromAuth(data, setCompanyName);
                    callBack(data);
                } else {
                    callBack({
                        isError: true,
                        message: "Invalid username or password",
                    });
                }
            })
            .catch(() => {
                callBack({
                    isError: true,
                    message: "Network Error! try again",
                });
            });
    }

    function requestResetPassword(email) {
        return ax().post("auth/RequestResetPassword", { email });
    }

    function sendEmail(data){
        return ax().post("auth/SendEmail", data);
    }

    function getPDF(ReportID){
        return ax().get("/web/ComplianceQuestion/GetPDF/" + ReportID);
    }

    function getReports() {
        return ax().get("web/ComplianceQuestion/GetReports");
    }

    function addReport() {
        return ax().post("web/ComplianceQuestion/AddReport");
    }

    function deleteReport(ReportID) {
        return ax().post("web/ComplianceQuestion/DeleteReport/" + ReportID);
    }

    function getCompanyByGuid(Guid) {
        return ax().get("/web/ComplianceQuestion/GetCompanyByGuid/" + Guid)
    }

    function getReportByGuid(Guid) {
        return ax().get("/web/ComplianceQuestion/GetReportByGuid/" + Guid)
    }

    function getComplianceQuestions() {
        return ax().get("web/ComplianceQuestion");
    }

    function getComplianceAnswers(ReportID){
        return ax().get("web/ComplianceQuestion/GetComplianceAnswer/" + ReportID)
    }

    function getComplianceReportResults(ReportID){
        return ax().get("web/ComplianceQuestion/GetComplianceReportResults/" + ReportID)
    }

    function updateComplianceAnswers(data) {
        return ax().post("web/ComplianceQuestion/UpdateComplianceAnswers", data);
    }

    function postComplianceAnswers(id) {
        return ax().post("web/ComplianceQuestion/PostComplianceAnswers", id);
    }

    function shareReport(request) {
        return ax().post("web/ComplianceQuestion/ShareReport", request)
    }

    function getMessages(selectedUserId) {
        return ax().get("/shared/chat/messages/" + selectedUserId);
    }

    function checkNewMessages() {
        return ax().get("/shared/chat/checkUnreadMessages");
    }

    function sendMessage(data) {
        return ax().post("/shared/chat/send", data);
    }

    function validatePayment(data) {
        return ax().post("/web/payments/ValidatePayment", data);
    }

    function updatePayment(data) {
        return ax().post("/web/payments/UpdatePaymentProfile", data);
    }

    function getDashboardVerifications() {
        return ax().get("web/DashboardReport/UpcomingVerification");
    }
    
    function getVerificationCount() {
        return ax().get("web/DashboardReport/VerificationCount");
    }

    function getMaintenanceCount() {
        return ax().get("web/DashboardReport/MaintenanceCount");
    }

    function updateAssignedTo(request) {
        return ax().post("web/StoreController/updateAssignedTo/", request);
    }

    function updateDueDate(request) {
        return ax().post("web/StoreController/updateDueDate/", request);
    }

    function getDashboardMaintenance() {
        return ax().get("web/DashboardReport/UpcomingMaintenance");
    }

    function getDashboardCompletedVerifications() {
        return ax().get("web/DashboardReport/CompletedVerifications");
    }

    function getDashboardCompletedMaintenance() {
        return ax().get("web/DashboardReport/CompletedMaintenance");
    }

    function getStores() {
        return ax().get("web/store");
    }

    function getStoreById(id) {
        return ax().get("web/store/" + id);
    }

    function registerUser(request) {
        return ax().post("web/users/", request);
    }

    function removeUser(userId) {
        return ax().delete("web/users/" + userId);
    }

    function getAllUsers() {
        return ax().get("web/users/all");
    }

    function getManagers() {
        return ax().get("web/users/managers");
    }

    function registerAdminUser(request) {
        return ax().post("web/auth/RegisterAdmin", request);
    }

    function getUser(userId) {
        return ax().get("web/users/" + userId);
    }

    function getChatUserList() {
        return ax().get("/shared/chat/users");
    }

    function registerStore(data) {
        return ax().post("web/store", data);
    }

    function updateStore(data) {
        return ax().put("web/store", data);
    }

    function getStoreMessage() {
        return ax().get("web/store/GetStoreMessage");
    }

    function getLanes() {
        return ax().get("web/lanes");
    }

    function getLaneById(laneId) {
        return ax().get("web/lanes/" + laneId);
    }

    function getLanesByStore(storeId) {
        return ax().get("web/lanes/getLanesByStore/" + storeId);
    }

    function saveLane(data) {
        return ax().post("web/lanes/", data);
    }

    function getDevices() {
        return ax().get("web/equipment");
    }

    function getDeviceById(deviceId) {
        return ax().get("web/equipment/" + deviceId);
    }

    function createDevice(data) {
        return ax().post("web/equipment", data);
    }

    function getDeviceModelList() {
        return ax().get("/web/equipment/equipmentModelList/");
    }

    function getLaneVerifications() {
        return ax().get("web/verification/lanes");
    }

    function getVerification(laneId) {
        return ax().get("web/verification/lanes/" + laneId);
    }

    function getLaneQuestions(laneId) {
        return ax().get("web/verification/device/questions/" + laneId);
    }

    function getMaintenance() {
        return ax().get("web/maintenance");
    }

    function getMaintenanceById(assetId) {
        return ax().get("web/maintenance/" + assetId);
    }

    function getDepartmentMaintenance(departmentId) {
        return ax().get("web/maintenance/" + departmentId);
    }

    function createMaintenance(data) {
        return ax().post("web/maintenance/", data);
    }

    // function getMaintenanceDevices(departmentId) {
    //   return ax().get("web/maintenance/asset/" + departmentId);
    // }

    function getMaintenanceLog(assetId) {
        return ax().get("web/maintenance/log/" + assetId);
    }

    function getMaintenanceLogResult(logId) {
        return ax().get("/web/maintenance/log/result/" + logId);
    }

    function getAssetLogs() {
        return ax().get("/web/assets/getAssetLogs");
    }

    function getDeviceLogResult(verificationLogId) {
        return ax().get(
            "/web/verification/deviceLogResult/" + verificationLogId
        );
    }

    function addQuestion(data) {
        return ax().post("web/verification/device/questions/", data);
    }

    function getVerificationDevices(verificationId) {
        return ax().get("/web/verification/devices/" + verificationId);
    }

    function createVerification(data) {
        return ax().post("web/verification", data);
    }

    function getDeviceLog(deviceId) {
        return ax().get("web/verification/deviceLog/" + deviceId);
    }

    function getAllDeviceLogs() {
        return ax().get("web/verification/AllDeviceLogs");
    }

    function getAsset(assetId) {
        return ax().get("web/assets/" + assetId);
    }

    function getAssets() {
        return ax().get("web/assets/");
    }

    function addAsset(data) {
        return ax().post("web/assets/", data);
    }

    function getDepartments() {
        return ax().get("web/departments/");
    }

    function getDepartmentsByStoreId(storeId) {
        return ax().post("web/departments/" + storeId);
    }

    function addDepartment(data) {
        return ax().post("web/departments/", data);
    }

    function getDepartment(departmentId) {
        return ax().get("web/departments/" + departmentId);
    }

    function setupPayments() {
        return ax().post("web/payments");
    }

    function saveStore(request) {
        return ax().post("web/users/RegisterCompany", request);
    }

    // super admin
    function getAllCompanies() {
        return ax().get("web/superAdmin/allCompanies");
    }

    function switchUsersList() {
        return ax().get("web/superAdmin/switchUsersList");
    }

    function switchCompany(companyId) {
        return ax().post("/web/superAdmin/switchCompany", { companyId });
    }

    function changeLogin(request) {
        return ax().post("/web/superAdmin/impersonate", request);
    }

    function getCompanyInfo() {
        return ax().get("/web/users/getCompanyInfo");
    }

    // function getLoginUser() {
    //     return ax().get("/web/users/user");
    // }

    function updateCompanyInfo(data) {
        return ax().post("/web/users/updateCompanyInfo", data);
    }

    // payments
    function getPaypalURL(data) {
        return ax().post("/web/payments/GetPaypalURL", data);
    }

    function deleteEquipment(data) {
        return ax().post("/web/equipment/DeleteEquipment", data);
    }

    function getPaymentToken(data) {
        return ax().post("/web/payments/GetAuthorizePaymentToken", data);
    }

    function sendPayPalResult(data) {
        return ax().post("/web/payments/SavePaypalSubscription", data);
    }

    function sendPayPalStoreUpdateResult(data) {
        return ax().post("/web/payments/PaypalActivatePendingStore", data);
    }

    function googleLogin(data) {
        return ax().post("/web/auth/signinGoogle", data);
    }

    function wasPreviousVerificationCompleted(laneId) {
        return ax().post("/web/verification/lanes/overdue/" + laneId);
    }

    function wasPreviousVerificationSuccessful(laneId) {
        return ax().post("/web/verification/lanes/success/" + laneId);
    }

    function wasPreviousMaintenanceCompleted(assetId) {
        return ax().post("/web/maintenance/overdue/" + assetId);
    }

    function wasPreviousMaintenanceSuccessful(assetId) {
        return ax().post("/web/maintenance/success/" + assetId);
    }

    function deleteStore(data) {
        return ax().post("web/store/DeleteStore", data);
    }

    function deleteLane(data) {
        return ax().post("/web/lanes/DeleteLane", data);
    }

    function deleteAsset(data) {
        return ax().post("/web/assets/DeleteAsset", data);
    }

    function deleteCompany(data) {
        return ax().post("/web/users/DeleteCompany", data);
    }

    function getVerificationByAsset(SerialNumber) {
        return ax().get("/web/verification/asset/" + SerialNumber);
    }

    function ax() {
        //var baseUlr = getApiURL();
        var baseUlr = "https://dev.pceyed.net";
        // var baseUlr = "http://localhost:44374";
        
        if (process.env.NODE_ENV == "development") {
            // baseUlr = "https://pcid-server-gk4.conveyor.cloud/";
            // baseUlr = "http://127.0.0.0:44374/";
            // baseUlr="https://192.168.8.101:45455/";
            // baseUlr="https://api.pceyed.net/";
        }

        const jwt = getUserJWT();

        return axios.create({
            baseURL: baseUlr,
            timeout: 1000000,
            headers: {
                Authorization: `Bearer ${jwt}`,
                "Content-Type": "application/json",
            },
        });
    }

    return {
        getVerificationByAsset,
        googleLogin,
        authenticate,
        setupPayments,
        saveStore,
        requestResetPassword,
        deleteEquipment,
        deleteStore,
        deleteLane,
        deleteAsset,
        deleteCompany,

        getMessages,
        getChatUserList,
        sendMessage,
        checkNewMessages,
        wasPreviousVerificationCompleted,
        wasPreviousMaintenanceCompleted,
        wasPreviousVerificationSuccessful,
        wasPreviousMaintenanceSuccessful,
        getVerificationCount,
        getMaintenanceCount,
        getDepartmentsByStoreId,

        getUser,
        getAllUsers,
        registerUser,
        registerAdminUser,

        getStores,
        getStoreById,
        registerStore,
        updateStore,

        getCompanyInfo,
        updateCompanyInfo,

        getManagers,
        removeUser,
        // getLoginUser,

        getLanes,
        saveLane,
        getLaneById,
        getLanesByStore,

        getMaintenance,
        createMaintenance,
        // getMaintenanceDevices,
        getMaintenanceById,
        getMaintenanceLog,
        getMaintenanceLogResult,

        getDepartmentMaintenance,

        getDevices,
        getDeviceById,
        createDevice,
        getDeviceModelList,

        getAssets,
        getAsset,
        addAsset,
        getAssetLogs,

        addDepartment,
        getDepartments,
        getDepartment,

        getLaneVerifications,
        createVerification,
        getVerificationDevices,
        getLaneQuestions,
        addQuestion,
        getDeviceLog,
        getAllDeviceLogs,
        getDeviceLogResult,
        getVerification,

        getPaymentToken,
        getPaypalURL,

        // super admin
        getAllCompanies,
        switchCompany,
        changeLogin,
        switchUsersList,

        getDashboardVerifications,
        getDashboardMaintenance,
        getDashboardCompletedMaintenance,
        getDashboardCompletedVerifications,

        validatePayment,
        updatePayment,
        sendPayPalResult,
        sendPayPalStoreUpdateResult,
        getStoreMessage,

        getReports,
        addReport,
        deleteReport,
        getComplianceQuestions,
        getComplianceAnswers,
        getComplianceReportResults,
        updateComplianceAnswers,
        postComplianceAnswers,
        shareReport,
        getCompanyByGuid,
        getReportByGuid,

        sendEmail,
        getPDF,
        
        updateAssignedTo,
        updateDueDate,
    };
};

export default ApiService;
